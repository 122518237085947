import React from 'react'
import { Container, Cols } from '../../styles'
import {
    FooterElement,
    FooterLegal
} from './FooterStyles'
import { Link } from 'gatsby'
import HRCLogo from '../../images/human-rights-campaign.svg'
import Obfuscate from 'react-obfuscate'

const Footer = () => {

    const practiceLinks = [   
        {
            label: "Practice Areas",
            linkUrl: "/practice-areas/"
        },          
        {
            label: "Auto Accidents",
            linkUrl: "/practice-areas/auto-accidents/"
        },
        {
            label: "Bicycle Accidents",
            linkUrl: "/practice-areas/bicycle-accidents/"
        },
        {
            label: "Brain Injuries",
            linkUrl: "/practice-areas/brain-injuries/"
        },
        {
            label: "Construction Accidents",
            linkUrl: "/practice-areas/construction-injuries/"
        },
        {
            label: "LGBTQIA Discrimination",
            linkUrl: "/practice-areas/lgbqt-discrimination-lawyer/"
        },
        {
            label: "Sexual Assault",
            linkUrl: "/practice-areas/sexual-assault-lawyer/"
        },
        {
            label: "Wrongful Termination",
            linkUrl: "/practice-areas/wrongful-termination-lawyer/"
        }
    ];

    const aboutLinks = [
        {
            label: "Company",
            linkUrl: "/about/"
        },          
        {
            label: "Client Reviews",
            linkUrl: "/reviews/"
        },
        {
            label: "About Us",
            linkUrl: "/about/"
        },
        {
            label: "Case Results",
            linkUrl: "/results/"
        },
        {
            label: "Personal Injury Blog",
            linkUrl: "/personal-injury-blog/"
        }
    ]

    const officeLocations = [
        {
            officeName: "El Segundo Office",
            officeAddress: "1960 E Grand Ave Suite 720",
            officeCityStateZip: "El Segundo, CA 90245"
        },
        {
            officeName: "West Hollywood Office",
            officeAddress: "9000 Sunset Blvd, Suite 710",
            officeCityStateZip: "West Hollywood, CA 90069"
        },
        {
            officeName: "San Diego Office",
            officeAddress: "5755 Oberlin Drive, Suite 301",
            officeCityStateZip: "San Diego, CA 92121"
        }
    ]

    return (
        <FooterElement>
            <Container>
                <Cols fourCols>
                    <div className="col">
                        <ul>
                            {practiceLinks.map(link => {
                                return <li key={link.label}>
                                        <Link to={link.linkUrl}>{link.label}</Link>
                                    </li>
                                
                            })}
                        </ul>
                    </div>
                    <div className="col">
                        <ul>
                            {aboutLinks.map(link => {
                                return <li key={link.label}>
                                        <Link to={link.linkUrl}>{link.label}</Link>
                                    </li>
                                
                            })}
                        </ul>
                    </div>
                    <div className="col">
                        <ul>
                            <li>
                                <Link to="/contact-us/">Contact</Link>
                            </li>
                            <li>
                                <Link to="/contact-us/">Free Case Evaluation</Link>
                            </li>
                            <li>
                                <Obfuscate
                                    email="info@craigcharleslaw.com"
                                    headers={{
                                        subject: 'Website Email Inquiry | Craig Charles Law'
                                    }}
                                />
                            </li>
                            <li>
                                <Obfuscate tel="424-343-0660" />
                            </li>
                        </ul>
                    </div>
                    <div className="col">
                        {officeLocations.map(office => {
                            return <ul key={office.officeName}>
                                <li>
                                    {office.officeName}
                                </li>
                                <li>
                                    {office.officeAddress}
                                </li>
                                <li>
                                    {office.officeCityStateZip}
                                </li>
                            </ul>
                        })}
                    </div>
                </Cols>
                <FooterLegal>
                    <div>
                        <img src={HRCLogo} width="150" height="56" alt="Human Rights Campaign Logo" />
                    </div>
                    <ul>
                        <li>
                            &copy; {new Date().getFullYear()} Craig Charles Law
                        </li>
                        <li>
                            <Link to="/">Privacy Policy</Link>
                        </li>
                        <li>
                            <a href="https://werra.io" rel="noreferrer noopener" target="_blank">Site Credits</a>
                        </li>
                    </ul>
                </FooterLegal>
            </Container>
        </FooterElement>
    )
}

export default Footer

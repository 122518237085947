import React from 'react'
import styled from 'styled-components'
import { PrimaryButton, defaultTheme } from '../styles'

const Toolbar = () => {
    return (
        <MobileToolbar>
            <PrimaryButton href="tel:4243430660" modifiers={['large', 'green', 'fullWidth']}>
                Get Help Now
            </PrimaryButton>
        </MobileToolbar>
    )
}

export default Toolbar

const MobileToolbar = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px 24px;
    width: 100%;
    background: ${defaultTheme.darkBlue};

    @media(min-width: 900px) {
        display: none;
    }
`;

import React, { useState, useRef } from 'react'
import { Container } from '../../styles/LayoutStyles'
import Logo from '../Logo'
import {
    HeaderMain,
    HeaderNav,
    HeaderLinkParent,
    HeaderLink,
    HeaderSubMenu,
    MenuButton
} from './HeaderStyles'

const Header = ({props}) => {

    const Links = [
        {
            label: "Practice Areas",
            linkUrl: "/practice-areas/"
        },
        {
            label: "About Us",
            linkUrl: "/about/"
        },
        {
            label: "Results",
            linkUrl: "/results/"
        },
        {
            label: "Resources",
            linkUrl: "/resources/",
            className: "parent",
            subMenu: [
                {
                    label: "Client Reviews",
                    linkUrl: "/reviews/",
                },
                {
                    label: "News & Insights",
                    linkUrl: "/personal-injury-blog/",
                }
            ]
        },
        {
            label: "Contact",
            linkUrl: "/contact-us/"
        },
    ]

    const transition = {
        duration: 0.25,
        ease: [0.43, 0.13, 0.23, 0.96]
    }

    const container = {
        hidden: { opacity: 0, height: "0" },
        show: {
            opacity: 1,
            height: "100vh",
            transition: {
                staggerChildren: 0.15,
                ...transition
            }
        }
    }
    
    const item = {
        hidden: {
            y: 5,
            opacity: 0,
        },
        show: {
            y: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -100 }
              }
        }
    }

    const [nav, showNav] = useState(false);
    const containerRef = useRef(null);

    return (  
        <>
            <HeaderMain>
                <Container flex alignCenter spaceBetween>
                    <Logo />
                    <HeaderNav
                        nav={nav}
                        initial={false}
                        animate={nav ? "show" : "hidden"}
                        ref={containerRef}
                        variants={container}
                    >
                        {Links.map(link => {
                            return <HeaderLinkParent key={link.label} variants={item}>
                                    <HeaderLink
                                        to={link.linkUrl}
                                        activeClassName={`header--active`}
                                        partiallyActive={true}
                                        
                                    >
                                        {link.label} 
                                    </HeaderLink>
                                {link.subMenu && link.subMenu.length > 0 ? (
                                    <HeaderSubMenu
                                        aria-label="submenu"
                                    >
                                        {link.subMenu.map((subLink) => (
                                            <li key={subLink.label}>
                                                <HeaderLink
                                                    to={subLink.linkUrl}
                                                    activeClassName={`header--active--sub`}
                                                    partiallyActive={true}
                                                >
                                                        {subLink.label}
                                                </HeaderLink>
                                            </li>
                                        ))}
                                    </HeaderSubMenu>
                                ) : null}
                            </HeaderLinkParent>
                        })}
                    </HeaderNav>
                    <MenuButton nav={nav} onClick={() => showNav(!nav)}>
                        Menu
                        <div>
                            <span />
                            <span />
                            <span />
                        </div>
                    </MenuButton>
                </Container>
            </HeaderMain>
        </>
    )
}

export default Header

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import {
    ContactWidgetElem,
    ContactWidgetForm,
    ShowFormButton,
    ContactFormWrapper
} from './ContactWidgetStyles'
import ContactForm from '../ContactForm'

const variants = {
    open: {
        // backgroundColor: "#FFF",
        opacity: 1,
        scale: 1,
        y: 0
    },
    closed: {
        opacity: 0,
        scale: 0.3,
        y: 50
    },
}

const ContactWidget = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <ContactWidgetElem>
            <ContactWidgetForm>
                <AnimatePresence initial={false}>
                    <motion.div
                        animate={isOpen ? "open" : "closed"}
                        variants={variants}
                    >
                        <ContactFormWrapper>
                            <h2>Free Case Review</h2>
                            <p className="casereview">
                                Fill out the form below for a complimentary case review or call <strong>(424) 343-0660</strong>
                            </p>
                            <ContactForm />
                        </ContactFormWrapper>
                    </motion.div>
                </AnimatePresence>
            </ContactWidgetForm>
            <ShowFormButton onClick={() => setIsOpen(isOpen => !isOpen)}>
                <svg height="100" width="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <g fill="none" fillRule="evenodd">
                        <path d="M31,30 L23.2667447,27.4400258 C21.1139553,28.477932 18.6364073,29.0689655 16,29.0689655 C7.71572875,29.0689655 1,23.2332288 1,16.0344828 C1,8.83573671 7.71572875,3 16,3 C24.2842712,3 31,8.83573671 31,16.0344828 C31,18.2477324 30.3651951,20.3321434 29.2451713,22.157731 L31,30 Z M28.3326774,26.9867968 L27.1438197,21.7055252 L27.533072,21.0748466 C28.4939426,19.5180139 29,17.7902301 29,16 C29,9.98336249 23.230764,5 16,5 C8.76923598,5 3,9.98336249 3,16 C3,22.0166375 8.76923598,27 16,27 C18.2728168,27 20.4554419,26.5057142 22.3864753,25.5802748 L23.0670771,25.2540993 L28.3326774,26.9867968 Z M16,18 C14.8954305,18 14,17.1045695 14,16 C14,14.8954305 14.8954305,14 16,14 C17.1045695,14 18,14.8954305 18,16 C18,17.1045695 17.1045695,18 16,18 Z M10,18 C8.8954305,18 8,17.1045695 8,16 C8,14.8954305 8.8954305,14 10,14 C11.1045695,14 12,14.8954305 12,16 C12,17.1045695 11.1045695,18 10,18 Z M22,18 C20.8954305,18 20,17.1045695 20,16 C20,14.8954305 20.8954305,14 22,14 C23.1045695,14 24,14.8954305 24,16 C24,17.1045695 23.1045695,18 22,18 Z" fill="inherit" fillRule="nonzero"/>
                    </g>
                </svg>
            </ShowFormButton>
        </ContactWidgetElem>
    )
}

export default ContactWidget

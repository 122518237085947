import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { defaultTheme } from './ThemeStyles'

export const Container = styled(motion.div)`
    margin: 0 auto;
    padding: 0 12px;
    width: 100%;

    @media(min-width: 1024px) {
        max-width: 960px;
    }

    @media(min-width: 1216px) {
        max-width: 1152px;
    }

    @media(min-width: 1408px) {
        max-width: 1244px;
    }

    @media(min-width: 1600px) {
        max-width: 1466px;
    } 

    ${props => props.fluid && css`
        margin: 0;
        padding: 0;
        max-width: 100%;
    `}

    ${props => props.flex && css`
        display: flex;
        flex-wrap: wrap;
    `}

    ${props => props.alignCenter && css`
        align-items: center;
    `}

    ${props => props.column && css`
        flex-direction: column;
    `}

    ${props => props.spaceBetween && css`
        justify-content: space-between;
    `}

    ${props => props.justifyCenter && css`
        justify-content: center;
    `}
    ${props => props.flexEnd && css`
        justify-content: flex-end;
    `};

    ${props => props.fullHeight && css`
        height: 100%;
    `}

    ${props => props.textCentered && css`
        text-align: center;
    `}
`

export const Mask = styled(motion.div)`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 5;
`

export const Grid = styled(motion.div)`
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 24px;

    ${props => props.threeCol && css`
        grid-template-columns: 1fr;

        @media(min-width: 700px) {
            grid-template-columns: 1fr 1fr;
        }

        @media(min-width: 900px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    `}

    ${props => props.twoCol && css`
        grid-template-columns: 1fr;

        @media(min-width: 700px) {
            grid-template-columns: 1fr 1fr;
        }
    `}
`;

export const PagePadding = styled.div`
    padding: 48px 0;

    @media(min-width: 900px) {
        padding: 96px 0;
    }
`;

export const Cols = styled.div`
    display: flex;
    flex-direction: column;

    @media(min-width: 600px) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    ${props => props.fourCols && css`
        @media(min-width: 600px) {
            .col {
                width: 50%;
            }
        }
        @media(min-width: 900px) {
            .col {
                width: 25%;
            }
        }
    `}

    ${props => props.twoColsSidebar && css`
        @media(min-width: 600px) {
            .col {
                &:first-child {
                    padding-right: 40px;
                    width: 66.667%;
                }
                &:last-child {
                    width: 33.333%;
                }
            }
        }
    `}
`;

export const Sidebar = styled(motion.div)`
    padding: 48px 0;
    width: 100%;
    overflow: hidden;

    @media(min-width: 900px) {
        padding: 96px 0;
        width: 35%;
    }

    > * {
        margin-bottom: 24px;
        @media(min-width: 900px) {
            margin-bottom: 40px;
        }
    }
`;

export const SidebarHeadingLabel = styled.div`
    padding: 24px 16px;
    background: ${defaultTheme.darkBlue};
    color: ${defaultTheme.white};
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.063em;
    text-transform: uppercase;

    @media(min-width: 900px) {
        margin-bottom: 32px;
    }
`;
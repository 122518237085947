import styled, { createGlobalStyle, css } from 'styled-components'
import { defaultTheme } from "./ThemeStyles"
import { motion } from "framer-motion"

export const Typography = createGlobalStyle`
    
    body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
               Roboto, Oxygen-Sans, Ubuntu, Cantarell,
               "Helvetica Neue", sans-serif;
        font-weight: 400;
    }

    .h2,
    h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.35;

        @media(min-width: 700px) {
            font-size: 32px;
        }

        @media(min-width: 1200px) {
            font-size: 40px;
        }

        &.sm {
            font-size: 20px;
                @media(min-width: 700px) {
                font-size: 24px;
            }

        @media(min-width: 1200px) {
            font-size: 30px;
        }
        }
    }

    .h3,
    h3 {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.35;

        @media(min-width: 700px) {
            font-size: 22px;
        }

        @media(min-width: 1200px) {
            font-size: 26px;
        }
    }

    .blue {
        color: ${defaultTheme.darkBlue};
    }
`

export const Eyebrow = styled(motion.p)`
    margin: 0;
    width: 80%;
    color: inherit;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.618;
    text-transform: uppercase;

    ${props => props.centered && css`
        margin: 0 auto;
        text-align: center;
    `};

    ${props => props.small && css`
        font-size: 12px;

        @media(min-width: 600px) {
            font-size: 14px;
        }
    `}

    ${props => props.large && css`
        font-size: 14px;

        @media(min-width: 600px) {
            font-size: 16px;
        }
    `}

    ${props => props.green && css`
        color: ${defaultTheme.green};
    `}
`;
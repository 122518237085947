import styled from 'styled-components'
import { defaultTheme } from '../../styles'

export const AssociationsElem = styled.section`
    padding: 40px 0;
    background: ${defaultTheme.darkBlue};

    .slick-track {
        display: flex;
        align-items: center;
    }
`;

export const ImageWrapper = styled.div`
    max-width: 150px;
`;

export const AssociationsSlide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 12px;
    color: #fff;

    @media(min-width: 900px) {
        padding: 0 24px;
    }

    img {
        /* max-width: 180px; */
    }
`;
import styled from 'styled-components'
import { defaultTheme } from '../../styles'
import { ContactFormElement } from '../ContactForm/ContactFormStyles' 

export const ContactWidgetElem = styled.div`
    @media(max-width: 900px) {
        display: none;
    }
`;

export const ShowFormButton = styled.button`
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: inline-block;
    height: 80px;
    width: 80px;
    background: ${defaultTheme.green};
    border: none;
    border-radius: 50%;
    cursor: pointer;

    svg {
        height: 65%;
        width: 65%;

        path {
            fill: ${defaultTheme.darkBlue};
        }
    }
`;

export const ContactWidgetForm = styled.div`
    position: fixed;
    right: 20px;
    bottom: 80px;
    margin-bottom: 20px;
    min-width: 430px;
    padding: 20px;
    z-index: 5;
`;

export const ContactFormWrapper = styled.div`
    padding: 20px 32px;
    background: ${defaultTheme.lightBlue};
    border-radius: 4px;
    box-shadow: 0px 1px 4px 3px rgb(0 0 0 / 15%);

    h2 {
        font-size: 28px;
    }

    .casereview {
        margin-bottom: 24px;
        max-width: 330px;
        line-height: 1.5;
    }

    button {
        width: 100%;
    }

    input,textarea {
        padding: 8px;
    }

    textarea {
        min-height: 160px;
    }
`;
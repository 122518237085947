import styled, { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { Link } from 'gatsby'
import { defaultTheme } from './ThemeStyles'

export const GlobalStyles = createGlobalStyle`
    ${normalize}
    
    html {
        box-sizing: border-box;
    }

    body {
        overflow-x: hidden;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    &::selection {
        background: ${defaultTheme.green};
        color: ${defaultTheme.darkBlue}
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ol {
        padding-left: 15px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    img {
        height: auto;
        width: 100%;
    }

    .centered {
        text-align: center;
    }

    blockquote {
        margin: 20px 0 20px 32px;
        font-family: georgia;
    }

    .cta {
        display: inline-block;
        padding: 16px 32px;
        background: ${defaultTheme.darkBlue};
        color: ${defaultTheme.white};
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;

        @media(min-width: 900px) {
            font-size: 16px;
        }
    }

`

export const LogoElement = styled(Link)`
    position: relative;
    display: inline-block;
    width: 100px;
    z-index: 2;
    /* filter: brightness(100); */

    @media(min-width: 900px) {
        width: 120px;
    }
`;

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from '../../styles'
import { AssociationsElem, AssociationsSlide, ImageWrapper } from './AssociationsStyles'
import Slider from 'react-slick'
import Image from 'gatsby-image'

const Associations = () => {

    var settings = {
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1260,
                settings: {
                  slidesToShow: 4,
                }
            },
            {
                breakpoint: 800,
                settings: {
                  slidesToShow: 3,
                }
            },
            {
                breakpoint: 450,
                settings: {
                  slidesToShow: 2,
                  centerMode: false
                }
            },
        ]
    };

    const data = useStaticQuery(graphql`
    {
      allAssociationsJson {
        nodes {
            associationBadgeUrl {
              sharp: childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            altText
        }
      }
    }
  `)
  
    return (
        <AssociationsElem>
            <Container>
                <Slider {...settings}>
                {data.allAssociationsJson.nodes.map(item => {
                    return <AssociationsSlide key={item.altText}>
                      <ImageWrapper>
                          <Image
                              fluid={item.associationBadgeUrl.sharp.fluid} 
                              alt={item.altText}
                          />
                        </ImageWrapper>
                    </AssociationsSlide>
                })}
                </Slider>
            </Container>
        </AssociationsElem>
    )
}

export default Associations

import styled from 'styled-components'
import { Link } from 'gatsby'
import rightArrowIcon from '../images/icon-rightarrow.svg'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { defaultTheme } from './index'
import { motion } from 'framer-motion'

const BUTTON_MODIFIERS = {
    large: () => `
        padding: 16px 32px;
        @media(min-width: 900px) {
            padding: 20px 40px;
        }
    `,
    small: () => `
        padding: 12px 24px;
    `,
    green: () => `
        background: ${defaultTheme.green};
        border: 1px solid ${defaultTheme.green};
        color: ${defaultTheme.darkBlue};
    `,
    greenSecondary: () => `
        border: 1px solid ${defaultTheme.green};
        color: ${defaultTheme.green};

        @media(hover: hover) and (pointer: fine) {
            &:hover {
                background: ${defaultTheme.green};
                color: ${defaultTheme.darkBlue};
            }
        }
    `,
    fullWidth: () => `
        display: block;
        width: 100%;
    `,
    arrow: () => `
        position: relative;
        
        &:after {
            position: relative;
            top: 2px;
            left: 0;
            display: inline-block;
            height: 20px;
            margin-left: 12px;
            width: 20px;
            background: url(${rightArrowIcon}) 0 0 / cover no-repeat;
            content: "";
            transition: ease-in-out 0.35s;
        }
        @media(hover: hover) and (pointer: fine) {
            &:hover {
                &:after {
                    left: 4px;
                }
            }
        }
    `
}

export const PrimaryButton = styled(motion(Link))`
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    transition: linear 0.25s;

    ${applyStyleModifiers(BUTTON_MODIFIERS)};

    @media(min-width: 900px) {
        padding: 16px 32px;
        font-size: 16px;
    }
`;

export const FormButton = styled(motion.button)`
    padding: 12px 24px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: linear 0.25s;

    ${applyStyleModifiers(BUTTON_MODIFIERS)};

    @media(min-width: 900px) {
        padding: 16px 32px;
        font-size: 16px;
    }
`;
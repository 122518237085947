import React from 'react'
import { GlobalStyles, Typography, Mask } from '../styles'
import Header from "../components/Header"
import Footer from "../components/Footer"
import { motion } from 'framer-motion'
import Associations from '../components/Associations'
import ContactWidget from '../components/ContactWidget'
import Toolbar from './Toolbar'

const transition = {
    duration: 0.5,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const Layout = ({ children }) => {
    return (
        <>
            <GlobalStyles />
            <Typography />
            <Header />
            <motion.main>
                {children}
            </motion.main>
            <Mask
                initial={{
                    opacity: "0",
                    height: "100%",
                    zIndex: "-1",
                    transition
                }}
                animate={{
                    opacity: "0",
                    height: "100%",
                    zIndex: "-1",
                    transition
                }}
                exit={{
                    background: "#0D1520",
                    opacity: "1",
                    height: "100%",
                    zIndex: "5",
                    transition
                }}
            />
            <Associations />
            <Footer />
            <ContactWidget />
            <Toolbar />
        </>
    )
}

export default Layout

import styled from 'styled-components'

export const ContactFormElement = styled.div`
    input,
    textarea {
        display: block;
        margin: 8px 0 32px;
        padding: 12px 8px;
        width: 100%;
    }

    textarea {
        min-height: 240px;
        resize: none;
    }
`;
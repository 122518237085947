import styled from 'styled-components'
import { Link } from 'gatsby'
import { defaultTheme } from './ThemeStyles'

export const PostItem = styled(Link)`
    display: block;
`;

export const PostThumbnail = styled.div`

`;

export const PostPreviewTitle = styled.h3`
    margin: 12px 0;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.618;

    @media(hover: hover) and (pointer: fine) {
        &:hover {
            /* color: ${defaultTheme.green}; */
        }
    }    
`;

export const PostCategory = styled.p`
    display: inline-block;
    margin: 14px 0 2px;
    padding: 8px 16px;
    background: ${defaultTheme.green};
    color: ${defaultTheme.darkBlue};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.063em;
    text-transform: uppercase;
`;